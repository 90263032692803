<template>
  <div>
    <v-row align="center" justify="center" class="mb-16">
      <v-card class="ma-4 pa-2 text-center sec-cart">
        <!-- <div class="lotto-scroll">
          <div class="lotto-group">
            <div class="pic">
              <img
                v-for="(cart, index) in carts"
                :key="index"
                :src="cart.lottery.urlImage"
                alt="235300"
                class="sizeimg"
              />
            </div>
          </div>
        </div> -->
        <v-row class="text-center mt-4" align="center" justify="center">
          <v-col cols="12">
            <img src="@/assets/check.png" width="150px" />
          </v-col>
          <v-col cols="12">
            <v-card>
              <h1 style="color: black">จองลอตเตอรี่สำเร็จ</h1>
              <h3 class="" style="color: red; font-size: 16px">
                ระบบจะทำการส่ง SMS แจ้งยืนยันออเดอร์ให้อีกครั้ง
              </h3>
              <h3 class="" style="color: red; font-size: 16px">
                หากไม่ได้รับ SMS ภายใน 15-30 นาที
              </h3>
              <h3 class="" style="color: red; font-size: 16px">
                กรุณาติดต่อกลับ 02-114-1326
              </h3>
            </v-card>
          </v-col>
        </v-row>
        <!-- <br /> -->
        <v-divider class="pt-2"></v-divider>
        <v-row class="text-center" align="center" justify="center" no-gutters>
          <v-col cols="12"
            ><span>หมายเลขคำสั่งซื้อ : {{ tShow.transactionNo }}</span></v-col
          >
          <v-col cols="12"
            ><span
              >สั่งซื้อเมื่อเมื่อ : {{ convertDate(tShow.createdAt) }}</span
            ></v-col
          >
          <v-col cols="12"
            ><span
              >สั่งซื้อโดย :
              {{ tShow.user.firstName + " " + tShow.user.lastName }}</span
            ></v-col
          >
        </v-row>
        <v-row
          class="text-center"
          align="center"
          justify="center"
          no-gutters
          v-if="tShow.isDelivery"
        >
          <!-- <v-col cols="12">
            <span>ที่อยู่จัดส่ง: {{ tShow.deliveryAddress }} </span>
          </v-col> -->
          <v-col cols="12">
            <span
              >ที่อยู่จัดส่ง:
              {{
                tShow.deliveryAddress.address +
                " " +
                tShow.deliveryAddress.subDistrict +
                " " +
                tShow.deliveryAddress.district +
                " " +
                tShow.deliveryAddress.province +
                " " +
                tShow.deliveryAddress.postcode
              }}
            </span>
          </v-col>
          <v-col cols="12">
            <span>สถานะการจัดส่ง: {{ tShow.deliveryStatus }} </span>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="4">หมายเลข</v-col>
          <v-col cols="4">ราคา</v-col>
          <v-col cols="4">ค่าบริการ</v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          v-for="(cart, index) in tShow.lotteryTransaction"
          :key="index"
        >
          <v-col cols="4">
            <h3 style="color: white">{{ cart.lottery.lotteryNo }}</h3>
          </v-col>
          <!-- <v-col cols="4">{{ cart.amount }} ใบ</v-col> -->
          <v-col cols="4"
            ><h3 style="color: white">{{ cart.lottery.price }} บาท</h3></v-col
          >
          <v-col cols="4"
            ><h3 style="color: white">{{ feePrice }} บาท</h3></v-col
          >
        </v-row>
        <v-divider class="py-4"></v-divider>
        <v-row align="center" justify="center">
          <v-col cols="4"><h3 style="color: white">รวมราคา</h3></v-col>
          <!-- <v-col cols="4">{{ index + 1 }} ใบ</v-col> -->
          <v-col cols="4"
            ><h3 style="color: white">{{ tShow.totalPrice }} บาท</h3></v-col
          >
        </v-row>
        <v-row class="mt-2 pb-10" justify="center"
          ><v-col cols="9">
            <v-btn
              style="
                background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
              "
              class="mt-2"
              @click="goToSafe()"
              >ลอตเตอรี่ของฉัน</v-btn
            ></v-col
          ></v-row
        >
      </v-card>
    </v-row>
  </div>
</template>
<script>
import ModaltermOfServices from "@/components/User/Modal/ModalTermofServices";
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
import moment from "moment";
export default {
  data() {
    return {
      tShow: [],
      userData: "",
      totalPrice: 0,
      totalAmount: 0,
      sumTotal: 0,
      cardNo: "",
      cardName: "",
      expireMonth: "",
      expireYear: "",
      ccv: "",
      lotteries: [],
      createdAt: "",
      feePrice: 0,
    };
  },
  created() {
    // this.getCart();
    this.getTransaction();
  },
  components: {
    ModaltermOfServices,
  },
  methods: {
    convertDate(val) {
      return moment(val).format("DD/MM/YYYY hh:mm");
    },
    home() {
      this.$router.push("/lotterythai");
    },
    goToSafe() {
      this.$router.push("/safe");
    },
    async getTransaction() {
      var tSuccess = JSON.parse(decode(localStorage.getItem("tSuccess")));
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user.token);
      console.log("tSuccess", tSuccess);

      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/` + tSuccess,
        auth
      );
      console.log("response", response.data.data);
      this.tShow = response.data.data;
      console.log("lotteryTransaction", this.tShow.lotteryTransaction);
      const responseConfig = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/OPERATION_PRICE`,
        auth
      );
      console.log("responseConfig", responseConfig.data.data);
      this.feePrice = responseConfig.data.data.value1 * 1;
    },
    // async getCart() {
    //   var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
    //   const auth = {
    //     headers: { Authorization: `Bearer ${user.token}` },
    //   };
    //   // console.log("user", user.token);
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/cart/findAllByUser/` + user.user.id,
    //     auth
    //   );
    //     console.log("response", response.data.data);
    //   this.carts = response.data.data;

    //   this.carts.forEach((cart) => {
    //     cart.lottery.urlImage = cart.lottery.urlImage.replace(
    //       "20210425",
    //       "20210424"
    //     );
    //     this.totalPrice = this.totalPrice + cart.lottery.price * cart.amount;
    //     this.totalAmount = this.totalAmount + cart.amount;
    //   });

    //   //   console.log(this.totalPrice);
    //   //   console.log(this.totalAmount);
    //   this.createTransaction(this.carts, this.totalPrice);
    // },
    // async createTransaction(carts, totalPrice) {
    //   let paymentData = JSON.parse(decode(localStorage.getItem("paymentData")));
    //   console.log("paymentData", paymentData);
    //   var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
    //   const auth = {
    //     headers: { Authorization: `Bearer ${user.token}` },
    //   };
    //   //   console.log("ข้อมูลuser", user);
    //   //   console.log("carts", carts);
    //   this.carts.forEach((cart) => {
    //     const data = {
    //       lotteryNo: cart.lottery.lotteryNo,
    //       lotteryId: cart.lotteryId,
    //     };
    //     this.lotteries.push(data);
    //   });
    //   const data = {
    //     totalPrice: totalPrice,
    //     isDelivery: paymentData.isDelivery,
    //     deliveryAddress: paymentData.address,
    //     status: "SUCCESS",
    //     userId: user.user.id,
    //     lotteries: this.lotteries,
    //   };
    //   console.log("dataที่ส่งไป", data);
    //   const response = await this.axios.post(
    //     `${process.env.VUE_APP_API}/transaction/create`,
    //     data,
    //     auth
    //   );
    //   console.log("สำเร็จ", response.data);
    //   if (response.data.response_status == "ERROR") {
    //     this.$swal.fire({
    //       icon: "warning",
    //       title: response.data.message,
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   }
    // },
  },
};
</script>
<style scoped>
.sec-cart {
  border-radius: 8px;
  padding: 8px;
  background: #2560a1;
  margin: 0 -2px 0px;
  color: #fff;
}
.lotto-group {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 47%;
  z-index: 20;
  background-color: #e8dfc4;
}

.lotto-scroll {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  -webkit-overflow-scrolling: touch;
}
.lotto-scroll .lotto-group {
  display: flex;
  width: auto;
  margin: 0 20px 0 0;
  padding: 0 0 10px;
  background: 0 0;
}
.lotto-group .info1 {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 60px;
  max-height: 120px;
  background: rgba(255, 94, 239, 0.85);
  backdrop-filter: blur(4px);
  text-align: center;
  color: #000;
  border-radius: 0 0 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
  font-size: 10px;
  padding: 10px 0;
}
.lotto-scroll .lotto-group .pic {
  display: flex;
  position: relative;
  top: unset;
  left: unset;
  bottom: unset;
  overflow: unset;
  width: auto;
  align-items: flex-start;
}
.sizeimg {
  width: 80%;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
